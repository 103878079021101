/* Colors 
  DarkOrange : #ac5e00
  Black : #000000
  White : #ffffff
  Light Orange : #e49944
*/

#root,
body {
  /* background-color: #0f0c0c; */
  background-image: linear-gradient(#000000dc, rgba(230, 230, 230, 0));
  background-repeat: no-repeat;
  height: 70vh;
  color: rgb(0, 0, 0) !important;
}

@font-face {
  font-family: "Doto";
  src: url("./assets/Doto.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lext";
  src: url("./assets/Lext.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/Merriweather.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Safari horizontal Scroll issue */

.scrolling-element {
  overflow-x: scroll; /* Must be 'scroll' not 'auto' */
  -webkit-overflow-scrolling: touch;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
/* Optional: show position indicator */
::-webkit-scrollbar-thumb {
  background-color: #ac5e00;
  border-radius: 10px;
  margin-top: 50px;

  /* width: 0;  */
  /* Remove scrollbar space */
}
::-webkit-scrollbar {
  width: 6px;
  background-color: #f1e4d42f;
  margin-top: 50px;
  border-radius: 10px;
}

.App {
  text-align: center;
  max-width: 78%;
  display: block;
  margin: auto;
  font-family: "Merriweather";
}

.App h1 {
  /* font-family: "Playfair Display", serif; */
}

.form-control:focus {
  border-color: #1b578e !important;
  box-shadow: inset 1px 1px rgb(211, 131, 12), 0 0 8px #1b578e !important;
}

.headerStyles {
  text-align: center;
  padding-top: 20px;
  border-radius: 15px !important;
}
.headerStyles .navBarContainer {
  max-width: 100%;
}

/* Hover-enabled dropdown */
.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
}

/* Translucent background for dropdown */
.custom-dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(5px);
  border: none;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.navbar-light .navbar-toggler-icon {
  /* background-color: #ac5e00 !important; */
  background-image: url(./assets/menu.png) !important;
}

.navBarStyles {
  padding: 2px;
  border-radius: 18px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #e49944 !important;
  transform: scale(1.1);
  font-size: 17px;
}

.nav {
  justify-content: space-evenly;
}

/* ContactUs Tabs */

.ContactTabStyle {
  margin: 35px;
}

.empContactUsCard {
  padding: 40px;
  border-radius: 18px;
  border-color: #1f1131;
  background-color: #fff;
  color: #331758;
  min-height: 410px;
  text-align: left !important;
}

/* overriding button styles on (selected) tabs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #000 !important;
  border-radius: 10px !important;
  width: 330px !important;
  height: 65px !important;
  font-size: 24px;
  margin: 5px;
}
/* overriding button styles on tabs */
/* .contactUsBody .nav-link {
  color: #fff !important;
  background-color: rgb(207, 143, 48) !important;
  border-radius: 10px !important;
  width: 330px !important;
  height: 65px !important;
  margin: 5px;
} */

.formParent {
  text-align: left;
}

.carouselStyles {
  background-color: #c2bdbdd2;
}

.cardStyles {
  padding: 10px;
  border-radius: 18px;
  /* background-color: #dee0e233; */
  background-color: rgb(255, 255, 255);
  color: #000;
  min-height: 380px;
  text-align: left !important;
}
.homePageCardStyles {
  padding: 40px;
  border-radius: 18px;
  /* background-color: #dee0e233; */
  background-color: rgba(255, 255, 255, 0.349);
  color: #000;
  min-height: 100vh;
  text-align: left !important;
}

.modal-content {
  background-color: antiquewhite !important;
  color: #000;
  border-color: antiquewhite;
  border-radius: 18px !important;
}

.cardStyles:hover {
  /* box-shadow: 4px 10px 14px rgba(245, 184, 93, 0.521); */
}

.empContactUsCard:hover {
  box-shadow: 5px 10px 16px #857f7f6b;
}

.Illustration {
  display: block;
  margin: auto;
  padding: 5px;
}

.fullWidthHomeCard {
  max-width: 100%;
  color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: transparent;
  margin: 5px 0px;
}

.fullWidthHomeCardX {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  border-radius: 18px;
  padding: 15px;
  box-shadow: 5px 10px 16px #857f7f6b;
  margin: 70px 0px;
}

.fullWidthContactCard {
  max-width: 100%;
  width: 100%;
}

.carousel-caption {
  position: initial !important;
  color: #000 !important;
}

.carousel-inner img {
  margin: auto;
}

.buttonWrapperCarousel {
  width: 95%;
  text-align: right;
}

.cardOneOfTwo {
  /* max-width: 50%;
  width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.329);
  padding: 20px;
}
.cardTwoOfTwo {
  /* max-width: 50%;
  width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #ddecd9;
  background-image: url(./assets/cardImageStu.jpg);
  background-size: cover;
}

.parentOfTwoCards,
.parentOfOneCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  /* flex-wrap: wrap; */
}

.whoWeAre {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 30px;
  /* flex-wrap: wrap; */
  padding: 60px;
  border-radius: 18px;
  color: #000;
  min-height: 780px;
  text-align: left !important;
}

.CardImages {
  max-width: 450px;
  display: block;
}

.CardImage {
  max-width: 600px;
  max-height: 350px;
  display: block;
  border-radius: 18px;
}

.cardHeading {
  width: 100%;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 380px;
}

.buttonGroup {
  width: 100%;
  margin: 15px;
  text-align: right;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.faqBody {
  margin-top: 30px;
  min-height: 100vh;
}

.faqStyle {
  font-size: 20px !important;
  color: #ffffff;
  background-color: #e49944;
  border-radius: 8px;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: transparent !important;
}
.accordion-button:focus {
  box-shadow: 0 0 0 0rem rgba(211, 131, 12, 0.363) !important;
  border-radius: 10px !important;
}

.accordion-item,
.accordion-button {
  background-color: transparent !important;
}

.workshopsBody {
  padding-bottom: 10px;
}

.fourCardRow {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.fourCardStyle {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 18px;
  width: 320px;
  /* background-color: rgba(255, 255, 255, 0.541); */
  background-color: rgba(255, 255, 255, 0.836);
  color: #000;
  padding-bottom: 20px;
  padding-top: 20px;
  box-shadow: 5px 10px 16px #857f7f6b;
}
.fourCardStyleNoBorder:hover {
  box-shadow: 3px 5px 8px #857f7f6b;
}

.fourCardStyleNoBorder {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 18px;
  width: 320px;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.541); */
  background-color: rgb(255, 255, 255);
  color: #000;
  /* box-shadow: 5px 10px 16px #857f7f6b; */
}

.fourCardStyle:hover {
  box-shadow: 4px 10px 14px rgba(245, 184, 93, 0.521);
}

.subsCardHeading {
  padding-top: 15px;
  font-weight: 500;
}

.recruiterBody {
  min-height: 100vh;
}

.recruiterBody .cardStylesRecruiter {
  background-color: #331758;
  color: rgb(221, 187, 34) !important;
}

.cardStylesRecruiter {
  padding: 40px;
  border: 1px solid rgb(221, 187, 34);
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.911);
  min-height: 400px;
  text-align: left !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bannerImage {
  width: 100%;
  background-size: contain;
  text-align: center;
  max-height: 350px;
}

.fullWidthFaqCard {
  background-color: transparent;
  min-height: none;
}

.fullWidthFaqCard:hover {
  box-shadow: none;
}

/* overriding button styles on tabs */
.faqBody .nav-link,
.contactUsBody .nav-link {
  color: #fff !important;
  background-color: rgba(207, 144, 48, 0.39) !important;
  border-radius: 10px !important;
  width: 330px !important;
  height: 65px !important;
  font-size: 24px;
  margin: 5px;
}

.fullWidth-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mainCarousel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
}

.headerStyles .bg-light {
  background-color: rgba(235, 232, 232, 0) !important;
}

.footerCardStyles {
  padding: 5% 12%;
  border-radius: 18px;
  max-width: 100%;
  width: 100%;
  /* background-color: #05213a; */
  background: linear-gradient(#000000, #000000);

  color: rgb(255, 255, 255);
  min-height: 410px;
  text-align: left !important;
  margin: 30px 0px;
}

.footerCardStyles .list-group-item {
  color: #e49944;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
}

.footerCardStyles .list-group-item:hover {
  text-decoration-line: underline;
}

.footerLinks {
  display: flex;
  font-size: x-small;
  justify-content: center;
}

.footerLinks .footerLinksFirstItem {
  font-size: 20px;
  /* font-weight: 500; */
  /* text-indent: 5%; */
}

.footerCardStyles .input-group > .form-control,
.input-group > .form-select {
  background-color: #c98c4633;
  max-width: 300px;
  color: white;
}
.footerCardStyles .input-group-text {
  background-color: #c98c4633;
  color: white;
}
.footerCardStyles ul li,
.contactUsBody ul li {
  display: inline;
  padding-right: 15px;
}

.footerCardStyles ul,
.contactUsBody ul {
  padding-left: 4px;
  font-size: small;
}

.footerCardStyles ul li :hover,
.contactUsBody .parentOfOneCard ul li :hover {
  transform: scale(1.4);
}

.navBarContainer .recruiterButton {
  border-radius: 16px;
  padding: 8px 25px;
  background-color: #1e5da5;
  border-color: #1e5da5;
  font-family: "Lato", sans-serif;
  letter-spacing: 5;
}

.navBarContainer .recruiterButton:hover {
  font-weight: 700;
}

.navBarContainer span {
  margin-top: "6.5px";
  margin-left: "5px" !important;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.headingSubtext .alert-link {
  color: #000;
}

.cardStyles .ctaButton {
  max-width: 290px;
  min-height: 55px;
  background-color: #ac5e00;
  font-size: 24px;
  border-color: #ac5e00;
  border-radius: 14px;
  margin-top: 30px;
  color: #ffffff;
  font-weight: 700;
  font-family: "Doto", sans-serif;
}

.cardStyles .btn-warning:hover {
  color: #000;
  background-color: #1b578e;
  border-color: #1b578e;
}

.cardStyles .btn-warning:focus {
  color: #000;
  background-color: #1b578e;
  border-color: #1b578e;
  box-shadow: 0 0 0 0.25rem rgb(217 164 6 / 50%);
}

.btn-outline-warning {
  border-color: #ac5f0000 !important;
  background-color: #ac5f002a !important;
  color: #000000 !important;
}

.btn-outline-warning:hover {
  background-color: #ac5f002a !important;
}

/* STUDENT PORTAL JSX STYLES ------------------------------------------------------- */

.loginSignup {
  margin: 100px 10px;
}

/* overriding button styles on (selected) tabs in StudentPortal.jsx */
.loginSignup .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #1b578e !important;
  border-radius: 10px !important;
  width: 130px !important;
  height: 100% !important;
  font-size: 18px;
  margin: 2px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.loginSignup .nav-link {
  color: #000;
  text-align: left;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.fullWidthButton {
  width: 100%;
}

.emptyCol {
  width: 10px;
  flex-grow: 0 !important;
}

.cardFixedHeight {
  height: 430px;
}

.cardFixedHeightSM {
  height: 150px;
}

/* New Styles */

.asktikText {
  font-size: x-small;
  padding: 30px;
}

.homepageCardCol {
  display: flex;
  justify-content: center;
}
.homepageCardColLeft {
  display: flex;
  justify-content: left;
}
.homepageCardColRight {
  display: flex;
  justify-content: right;
}

.btn-success {
  background-color: #1b578e !important;
}

.CardImageIconHomePage {
  display: block;
  align-self: center;
}

.CardImageBlog {
  display: block;
  align-self: center;
  /* border-radius: 12px; */
  box-shadow: 2px 3px 4px #857f7f6b;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.CustomCardRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}

.CustomTwoCardRow {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.CustomTwoCardRow .list-group-item {
  background-color: transparent;
}

.clientImage {
  display: block;
  align-self: center;
  padding: 5px;
  max-width: 69%;
}

.CustomCard {
  margin: 15px;
}

.me .fourCardStyleNoBorder:hover {
  box-shadow: none;
}

.headingFont {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.3;
  padding-bottom: 45px;
  padding-top: 0;
}

.headingFontM {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.3;
  padding-bottom: 25px;
  padding-top: 10px;
}

.mainSubHeading {
  font-size: 24px;
  line-height: 1.5;
}

.bodyTxt {
  font-size: 18px;
}

.mainTitle {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.41667;
}

.firstBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 70%;
}

.nopaddingHeading {
  padding: 10px 60px 30px 60px;
  text-align: center;
}

.two-color-text {
  font-family: 'Lext' !important;
  font-size: 36px;
  font-weight: 800;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 80%,
    #e49944 80%,
    #e49944 100%
  );
  background-clip: text;
  color: transparent;
}

.mmListWrapper .list-group-item {
  border: none;
  padding-left: 0;
  font-size: 18px;
}

@media only screen and (max-width: 1150px) {
  .homepageCardCol,
  .homepageCardColLeft,
  .homepageCardColRight {
    display: flex;
    justify-content: space-around;
  }

  .firstBlock {
    max-width: 100%;
  }
  .headingFont {
    font-size: 35px;
  }

  .CardImages {
    max-width: 250px;
  }

  .footerLinks .footerLinksFirstItem {
    font-size: 15px;
  }

  .mainSubHeading {
    font-size: 18px;
  }

  .mainSubHeadingSm {
    font-size: 17px;
  }

  .nopaddingHeading {
    padding: 10px 10px 10px 10px;
    text-align: center;
  }

  .cardStyles .ctaButton {
    max-width: 405px;
  }

  .btn-light:hover {
    color: #ac5e00 !important;
    background-color: #f9fafb;
    border-color: #ac5e00 !important;
  }

  .App {
    max-width: 99%;
  }

  /* increasing vh for phone */
  #root,
  body {
    height: 120vh;
    width: 100vw;
  }

  /* Changing card Layout for phones */
  .CustomCardRow {
    justify-content: space-around;
  }
  .clientImage {
    max-width: 100%;
    padding: 5px;
  }

  /* Long width image */

  .longWidthImage {
    display: block;
    width: 300px;
    max-width: 300px;
    padding-top: 10px;
  }

  .mmListWrapper .list-group-item {
    font-size: 18px;
  }
}

/* custom Css for just changing colors of button */

.btnColor {
  background-color: #e49944 !important;
  border-color: #e49944 !important;
}

.btnColor:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.logo {
  padding-left: 0.5rem;
}

.mmTik {
  max-width: 15px;
  margin-right: 10px;
}

.longWidthImage {
  display: block;
  width: "388px";
  max-width: "388px";
  padding-top: 10px;
}
